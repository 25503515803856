<template>

  <el-form :class="type == 'view' ? 'form-read-only' : ''"
           v-loading="loadingData"
           :model="objData"
           :rules="rules"
           ref="formRef"
  >
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">

        <div class="row g-9 mb-7">
          <div class="col-md-6 fv-row" v-for="language in languages">
            <label class="required fs-6 fw-bold mb-2">Name ({{ language }})</label>
            <el-form-item :prop="'name.'+language" :rules="rules['field']">
              <el-input v-model="objData.name[language]" :placeholder="`Name(${language})`"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Country</label>
            <el-form-item prop="country_id" :rules="rules['field']">
              <el-select v-model="objData.country_id" class="select-display-block">
                <el-option v-for="country in countries" :label="country.name"
                           :value="country.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="required fs-6 fw-bold mb-2">Governorate</label>
            <el-form-item prop="country_id" :rules="rules['field']">
              <el-form-item prop="governorate_id" :rules="rules['field']">
                <el-select  v-model="objData.governorate_id" clearable class="select-display-block"
                           :disabled="loadingGovernorates">
                  <el-option v-for="governorate in governorates" :key="governorate.id" :label="governorate.name"
                             :value="governorate.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form-item>
          </div>
          <div class="col-md-4 fv-row">
            <label class="fs-6 fw-bold mb-2">Is Active</label>
            <el-form-item>
              <el-switch v-model="objData.is_active"/>
            </el-form-item>
          </div>
        </div>
        <div class="row g-9 mb-7">
          <GMapMap
              ref="gmap"
              :center="center"
              :zoom="12"
              style="width: 100%; height: 400px"
          >
<!--            <GMapMarker-->
<!--                :key="index"-->
<!--                v-for="(m, index) in markers"-->
<!--                :position="m.position"-->
<!--                :clickable="true"-->
<!--                :draggable="true"-->
<!--                @dragend="onMarkerDragend($event)"-->

<!--            />-->
            <GMapAutocomplete
                class="input controls search-box-input el-input__inner"
                type="text"
                ref="searchBoxInput"
                @place_changed="setPlace"
            >
              <!--      :placeholder="trans('search')"-->
            </GMapAutocomplete>
            <GMapPolygon
                :draggable="true"
                :editable="true"
                :options="options"
                :paths="objData.paths"
                @paths_changed="onPolygonPathUpdate"
            />
          </GMapMap>
        </div>
      </div>
    </div>

    <div class="row g-9 mb-7">
      <div class="col-md-4 fv-row">

      </div>
      <div class="col-md-4 fv-row">
        <button v-if="type != 'view'"
                @click="submitForm()"
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary full-width"
                type="button"
                :disabled="loading">
              <span v-if="!loading" class="indicator-label">
                Submit
              </span>
          <span v-if="loading" class="indicator-progress">
                Please wait...
                <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
        </button>
      </div>
      <div class="col-md-4 fv-row">

      </div>
    </div>

  </el-form>

</template>

<script>
import {defineComponent, ref, toRefs,watch} from "vue";
import {getIllustrationsPath} from "@/core/helpers/assets";
import {useRoute} from 'vue-router'
import ApiAxiosService from "@/core/services/ApiAxiosService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {handleError, handleSuccess} from "@/core/helpers/response";
import router from "@/router";
import {APIs} from "@/store/enums/ApiEnums";
import ImageComp from "@/components/admin/general/ImageComp";
import { useRouter } from 'vue-router'
import { getGovernorates} from "@/core/helpers/remote_data";

export default defineComponent({
  name: "city-comp",
  components: {ImageComp},
  props: ['type'],
  setup(props) {
    const router = useRouter();
    const {type} = toRefs(props);
    const route = useRoute();
    const formRef = ref(null);
    const objData = ref({});
    const loading = ref(null);
    const loadingData = ref(false);
    const multiLanguageFields = ref(['name' , 'paths']);
    const isIndeterminate = ref(true)
    const languages = ref(['en', 'ar']);
    const countries = ref([]);
    const governorates = ref([]);
    const loadingGovernorates = ref(false);
    const markers = ref([
      {
        position: {
          lat: 29.33628, lng: 47.67905
        },
      }
    ])
    // google map
    const center = ref({lat: 29.33628, lng: 47.67905});
    const options = ref({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    });
    objData.value = {
      name: {},
      governorate_id : '',
      is_active: true,
      paths : [
        { lat: 29.33628, lng: 47.67905 },
        { lat: 29.33628, lng: 47.67905 },
      ]
    };

    const rules = ref({
      field: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    });
    const initData = async () => {
      let response = null;
      loadingData.value = true;
      switch (props.type) {
        case 'create' :
          try {
            response = await ApiAxiosService.get(APIs.CITY.create);
            languages.value = response.data.data.languages;
            countries.value = response.data.data.countries;
          } catch (e) {
            handleError(e)
          }

          break;
        case 'edit' :
          try {
            response = await ApiAxiosService.get(APIs.CITY.edit(route.params.id));
            governorates.value = response.data.data.governorates;
            objData.value = response.data.data.city;
            languages.value = response.data.data.languages;
            countries.value = response.data.data.countries;
            if(objData.value.paths[0].lat && objData.value.paths[0].lng) {
              center.value = {lat : objData.value.paths[0].lat , lng : objData.value.paths[0].lng}
            }

          } catch (e) {
            handleError(e)
          }

          break;
        case 'view' :
          try {
            response = await ApiAxiosService.get(APIs.CITY.show(route.params.id));
            objData.value = response.data.data.city;
            languages.value = response.data.data.languages;
            countries.value = response.data.data.countries;

          } catch (e) {
            handleError(e)
          }

          break;
      }
      loadingData.value = false;

    }

    const submitForm = () => {

      formRef.value.validate((valid) => {
        if (valid) {
          switch (props.type) {
            case 'create' :
              storeResource();
              break;
            case 'edit' :
              updateResource();
              break;
          }

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    }
    const storeResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      ApiAxiosService.post(APIs.CITY.store, formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'cities-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const updateResource = () => {
      var formData = new FormData();
      Object.keys(objData.value).forEach(function (key) {
        formData.append(key, multiLanguageFields.value.includes(key) ? JSON.stringify(objData.value[key]) : objData.value[key]);
      });
      loading.value = true;
      formData.append('_method' , 'put');
      ApiAxiosService.post(APIs.CITY.update(route.params.id), formData).then(function (res) {
        loading.value = null;
        handleSuccess(res);
        setTimeout(function () {
          router.push({name: 'cities-list'})
        }, 1000)
      }).catch(function (err) {
        loading.value = null;
        handleError(err);
      });
    }
    const onPolygonPathUpdate = function (mvcArray) {
      let path = [];
      for (let i = 0; i < mvcArray.getLength(); i++) {
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          path.push({
            lat: mvcArray.getAt(i).getAt(j).lat(),
            lng: mvcArray.getAt(i).getAt(j).lng(),
          })
        }
      }
      objData.value.paths = path;

    };
    const setPlace = function (place) {
      if(place && place.geometry && place.geometry.location) {
        let location = place.geometry.location;
        center.value = {
          lat: location.lat(),
          lng: location.lng(),
        };
        objData.value.paths = [  { lat: location.lat(), lng: location.lng() }, { lat: location.lat(), lng: location.lng() },];
      }

    };
    const onMarkerDragend = function ({latLng}) {
      console.log(latLng.lat() + " "+ latLng.lng())
    };

    watch(() => objData.value.country_id, async (currentValue, oldValue) => {
      loadingGovernorates.value = true;
      // governorates.value = [];
      governorates.value = await getGovernorates(currentValue);
      if (!governorates.value.find(el => el.id == objData.value.governorate_id)) {
        objData.value.governorate_id = "";
      }
      loadingGovernorates.value = false;
    });
    initData();


    return {
      getIllustrationsPath,
      formRef,
      objData,
      loading,
      loadingData,
      rules,
      initData,
      submitForm,
      storeResource,
      updateResource,
      type,
      isIndeterminate,
      languages,
      countries,

      center,
      options,
      onPolygonPathUpdate,
      setPlace,
      markers,
      // onMarkerDragend

      governorates,
      loadingGovernorates
    };
  },

});
</script>

